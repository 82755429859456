<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="40%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- name -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Nama Cluster"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="name"
                        has-feedback>
                        <AInput
                            placeholder="nama cluster"
                            :disabled="form.readOnly"
                            v-model:value="form.name" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- status -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Status"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="status"
                        has-feedback>
                        <ARadioGroup
                            v-model:value="form.status"
                            style="width:100%">
                            <a-radio :value="true">Aktif</a-radio>
                            <a-radio :value="false">Non Aktif</a-radio>
                        </ARadioGroup>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            name: null,
            status: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Master Cluster',
            endpoint: {
                create: '/api/cluster/create',
                update: `/api/cluster/update/${props.item.id}`,
                show: `/api/cluster/show/${props.item.id}`,
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Nama tidak boleh kosong!',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: 'Status tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint.create)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            } else {
                form.post(state.endpoint.update)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            }

        }

        const fetchById = () => {
            apiClient.get(state.endpoint.show)
                .then(({ data }) => {
                    form.name = data.name
                    form.status = data.status
                })
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                fetchById()

                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Master Cluster`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
